import { useState, useEffect } from "react";
import { Alert, Button, Box } from "@mui/material";
import { emit } from "@tauri-apps/api/event";
import packageJson from "../../package.json";
import { useGetOperatorAppActiveVersionQuery } from "../generated/graphql";
import useAuth from "../hooks/useAuth";

const UpdateAlert = () => {
  const [open, setOpen] = useState(false);
  const { jwt } = useAuth();
  const [
    { data: getOperatorAppActiveVersionData, error },
    getOperatorAppActiveVersion,
  ] = useGetOperatorAppActiveVersionQuery({});
  const operatorAppActiveVersion =
    getOperatorAppActiveVersionData?.getOperatorAppActiveVersion;

  useEffect(() => {
    if (
      operatorAppActiveVersion &&
      packageJson.version !== operatorAppActiveVersion
    ) {
      setOpen(true);
    }
  }, [operatorAppActiveVersion]);

  const handleUpdate = () => {
    emit("ready-for-update", {
      jwt,
    });
  };

  return open ? (
    <Box
      sx={{
        width: "100%",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1400, // Higher than default MUI AppBar (1100)
      }}
    >
      <Alert
        severity="warning"
        sx={{
          width: "100%",
          justifyContent: "space-between",
          borderRadius: 0,
          "& .MuiAlert-message": {
            flex: 1,
          },
          "& .MuiAlert-action": {
            paddingLeft: 2,
            marginRight: 0,
          },
        }}
        action={
          <Button color="inherit" size="small" onClick={handleUpdate}>
            Update Now
          </Button>
        }
      >
        Please click on Update Now to install the latest version of the operator
        app to ensure optimal performance and access to the newest features.
      </Alert>
    </Box>
  ) : null;
};

export default UpdateAlert;
