// @ts-nocheck
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Card,
  Stack,
  Link,
  Container,
  Typography,
  Alert,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Badge,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useResponsive from "../hooks/useResponsive";
import useAuth from "../hooks/useAuth";
import Page from "../components/Page";
import Logo from "../components/Logo";
import {
  checkUpdate,
  installUpdate,
  onUpdaterEvent,
} from "@tauri-apps/api/updater";
import { relaunch } from "@tauri-apps/api/process";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import toast from "react-hot-toast";
import { TwoFactorAuth } from "../components/TwoFactorAuth";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
    maxHeight: "100vh",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: "50%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: 10,
  margin: theme.spacing(2, 0, 2, 2),
  boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px",
  backgroundPosition: "bottom !important",
  background: `url(${"/assets/login1.png"}) no-repeat center top / cover`,
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { login } = useAuth();
  const navigate = useNavigate();

  const isStaging =
    import.meta.env.VITE_APP_API?.includes("staging") ||
    import.meta.env.VITE_APP_API?.includes("localhost");

  const isSandbox = import.meta.env.VITE_APP_API?.includes("sandbox");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [requires2FA, setRequires2FA] = useState(false);

  const smUp = useResponsive("up", "sm");
  const mdUp = useResponsive("up", "md");

  const handleClickLogin = async () => {
    localStorage.removeItem("jwt");

    try {
      setLoading(true);
      const loginResponse = await login(email, password, null);
      if (loginResponse.toString().includes("403")) {
        setRequires2FA(true);
      } else if (loginResponse.toString().includes("401")) {
        toast.error("Failed to login");
        setLoading(false);
      } else if (loginResponse) {
        toast.success("Logged in!");
        navigate("/players");
      }
    } catch (error) {
      console.error(error);
      setError(error?.message);
    } finally {
      setLoading(false);
    }
  };

  const handle2FASubmit = async (code: string) => {
    try {
      const loginResponse = await login(email, password, code);
      if (loginResponse.toString().includes("403")) {
        setRequires2FA(true);
      } else if (loginResponse.toString().includes("401")) {
        toast.error("Failed to login");
        setLoading(false);
      } else if (loginResponse) {
        toast.success("Logged in!");
        navigate("/players");
      }
      // Handle successful login
    } catch (error) {
      setError("Invalid 2FA code. Please try again.");
    }
  };

  return (
    <Page title="Login">
      <RootStyle>
        {mdUp && (
          <SectionStyle
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#454F5B",
            }}
          >
            <Box
              sx={{
                px: 5,
                position: "absolute",
                top: "40%",
                zIndex: 1,
                mb: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            />
            <img src="/logo.png" alt="login" width="300px" />
            <img
              src="/assets/pet.png"
              alt="login"
              width="150px"
              style={{ marginTop: 20 }}
            />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Badge
                  badgeContent={
                    isStaging ? "Staging" : isSandbox ? "Sandbox" : null
                  }
                  color="secondary"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <Typography variant="h2">Operator</Typography>
                </Badge>
              </Box>
            </Stack>

            {requires2FA ? (
              <Box sx={{ mt: 4 }}>
                <TwoFactorAuth
                  onSubmit={handle2FASubmit}
                  loading={loading}
                  error={error}
                />
              </Box>
            ) : (
              <>
                <TextField
                  fullWidth
                  label="Email"
                  name="Email"
                  autoComplete="Email"
                  autoFocus
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{
                    mt: 2,
                    "& .MuiOutlinedInput-root": {
                      // "& fieldset": {
                      //   borderColor: "white", // This will change the border color
                      // },
                      "&.Mui-focused fieldset": {
                        borderColor: "white",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "white", // This will change the label color
                      "&.Mui-focused": {
                        color: "white",
                      },
                    },
                    "& .MuiFilledInput-input": {
                      backgroundColor: "lightgray", // change this to your desired color
                    },
                  }}
                />

                <FormControl
                  variant="outlined"
                  fullWidth
                  sx={{
                    mt: 2,
                    "& .MuiOutlinedInput-root": {
                      // "& fieldset": {
                      //   borderColor: "white", // This will change the border color
                      // },
                      "&.Mui-focused fieldset": {
                        borderColor: "white",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "white", // This will change the label color
                      "&.Mui-focused": {
                        color: "white",
                      },
                    },
                    "& .MuiFilledInput-input": {
                      backgroundColor: "lightgray", // change this to your desired color
                    },
                  }}
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password *"
                  />
                </FormControl>

                <LoadingButton
                  id={"login-button"}
                  fullWidth
                  color="primary"
                  variant="contained"
                  size="large"
                  type="submit"
                  loading={loading}
                  sx={{
                    mt: 3,
                    textTransform: "initial",
                    fontWeight: "400",
                  }}
                  onClick={() => handleClickLogin()}
                >
                  Sign In
                </LoadingButton>

                {error !== "" && (
                  <Alert sx={{ mt: 4 }} severity="error">
                    {error}
                  </Alert>
                )}

                <Typography
                  variant="body2"
                  component={RouterLink}
                  to="/auth/forgot-password"
                  sx={{ mt: 2, textAlign: "center", color: "text.secondary" }}
                >
                  Forgot password?
                </Typography>
              </>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
