import { useState } from "react";
// @mui
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  Alert,
  TableSortLabel,
  TableCell,
  TableRow,
} from "@mui/material";
import { orderBy } from "lodash";

interface PlayerPetsProps {
  player: any;
}
export default function PlayerPets({ player }: PlayerPetsProps) {
  const playerPets = player.pets;

  const [sortDirectionAsc, setSortDirectionAsc] = useState<boolean>(false);
  const [sortByAttribute, setSortByAttribute] = useState<string>("date");

  const sortItems = (items: any) => {
    const direction = sortDirectionAsc ? "asc" : "desc";
    const sorted = orderBy(items, (o) => o[sortByAttribute], direction);
    return sorted;
  };

  const changeSort = (attribute: any) => {
    if (attribute === sortByAttribute) {
      const newSort = !sortDirectionAsc;
      setSortDirectionAsc(newSort);
    } else setSortByAttribute(attribute);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: { xs: "auto", sm: "50px" },
          width: { xs: "100%", sm: "auto" },
          mb: 2,
        }}
      >
        {playerPets?.length === 0 ? (
          <Alert severity="warning">No Pets Found!</Alert>
        ) : (
          <Alert severity="info">Total {playerPets?.length} Pets Found</Alert>
        )}
      </Box>

      <TableContainer component={Paper}>
        <Table aria-label="collapsible table" sx={{ overflowX: "scroll" }}>
          <TableHead>
            <TableRow sx={{ background: "#2d2f38" }}>
              <TableCell align="left">
                <TableSortLabel
                  onClick={() => changeSort("type")}
                  active={sortByAttribute === "type"}
                  direction={sortDirectionAsc ? "asc" : "desc"}
                >
                  Pet Type
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">
                <TableSortLabel
                  onClick={() => changeSort("amount")}
                  active={sortByAttribute === "amount"}
                  direction={sortDirectionAsc ? "asc" : "desc"}
                >
                  Name
                </TableSortLabel>
              </TableCell>

              <TableCell align="left">
                <TableSortLabel
                  onClick={() => changeSort("candyEarned")}
                  active={sortByAttribute === "candyEarned"}
                  direction={sortDirectionAsc ? "asc" : "desc"}
                >
                  Candy Earned
                </TableSortLabel>
              </TableCell>

              <TableCell align="left">
                <TableSortLabel
                  onClick={() => changeSort("candyToNextLevel")}
                  active={sortByAttribute === "candyToNextLevel"}
                  direction={sortDirectionAsc ? "asc" : "desc"}
                >
                  Candy To Next Level
                </TableSortLabel>
              </TableCell>

              <TableCell align="left">
                <TableSortLabel
                  onClick={() => changeSort("level")}
                  active={sortByAttribute === "level"}
                  direction={sortDirectionAsc ? "asc" : "desc"}
                >
                  Level
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {playerPets?.length === 0 && (
              <TableRow sx={{ background: "#3d4155" }}>
                <TableCell colSpan={7} align="center">
                  No Pets found
                </TableCell>
              </TableRow>
            )}
            {sortItems(playerPets)?.map((pet: any, index: any) => {
              return <PlayerPetRow key={pet.id} pet={pet} index={index} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

const PlayerPetRow = (props: { pet: any; index: number }) => {
  const { pet, index } = props;
  const isEven = index % 2 === 0;

  return (
    <>
      <TableRow
        key={pet.id}
        sx={{
          backgroundColor: isEven ? "#2f3140" : "#3d4155",
          height: "45px",
        }}
      >
        <TableCell align="left">{pet.type}</TableCell>
        <TableCell align="left">{pet.name}</TableCell>
        <TableCell align="left">{pet.candyEarned}</TableCell>
        <TableCell align="left">{pet.candyToNextLevel}</TableCell>
        <TableCell align="left">{pet.level}</TableCell>
      </TableRow>
    </>
  );
};
