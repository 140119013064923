import {
  Alert,
  Box,
  Button,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useGetPlayTransactionListByPlayerIdQuery } from "../generated/graphql";
import { orderBy } from "lodash";
import SvgIconStyle from "./other/SvgIconStyle";

// ----------------------------------------------------------------------
interface PlayerPlayTransactionsProps {
  player: any;
}

export default function PlayerPlayTransactions({
  player,
}: PlayerPlayTransactionsProps) {
  // Pagination
  const [cursor, setCursor] = useState<string | null>(null);
  const [limit, setLimit] = useState<number>(25);

  // Sorting
  const [sortDirectionAsc, setSortDirectionAsc] = useState<boolean>(false);
  const [sortByAttribute, setSortByAttribute] = useState<string>("date");

  // Concated List
  const [concatedList, setConcatedList] = useState<any[]>([]);

  const [
    { data: getPlayerTransactionsData, error: getPlayerTransactionsError },
  ] = useGetPlayTransactionListByPlayerIdQuery({
    variables: {
      playerId: player.id,
      pagination: {
        limit: limit,
        cursor: cursor,
      },
    },
  });

  const transactions =
    getPlayerTransactionsData?.getPlayTransactionListByPlayerId.transactions;
  const _cursor =
    getPlayerTransactionsData?.getPlayTransactionListByPlayerId.cursor;
  const _hasMore =
    getPlayerTransactionsData?.getPlayTransactionListByPlayerId.hasMore;
  const _totalCount =
    getPlayerTransactionsData?.getPlayTransactionListByPlayerId.totalCount;

  // concat transactions list on new data
  // - If previous data is same as new data then don't concat
  useEffect(() => {
    if (transactions) {
      setConcatedList((prev) => {
        if (JSON.stringify(prev) === JSON.stringify(transactions)) {
          return prev;
        }
        return [...prev, ...transactions];
      });
    }
  }, [transactions]);

  const changeSort = (attribute: any) => {
    if (attribute === sortByAttribute) {
      const newSort = !sortDirectionAsc;
      setSortDirectionAsc(newSort);
    } else setSortByAttribute(attribute);
  };

  const sortItems = useMemo(() => {
    const direction = sortDirectionAsc ? "asc" : "desc";
    return orderBy(concatedList, (o) => o[sortByAttribute], direction);
  }, [concatedList, sortByAttribute, sortDirectionAsc]);

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table" sx={{ overflowX: "scroll" }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#2d2f38" }}>
              <TableCell align="left">
                <TableSortLabel
                  onClick={() => changeSort("type")}
                  active={sortByAttribute === "type"}
                  direction={sortDirectionAsc ? "asc" : "desc"}
                >
                  Type
                </TableSortLabel>
              </TableCell>

              <TableCell align="left">
                <TableSortLabel
                  onClick={() => changeSort("gameName")}
                  active={sortByAttribute === "gameName"}
                  direction={sortDirectionAsc ? "asc" : "desc"}
                >
                  Game Name
                </TableSortLabel>
              </TableCell>

              <TableCell align="left">
                <TableSortLabel
                  onClick={() => changeSort("amount")}
                  active={sortByAttribute === "amount"}
                  direction={sortDirectionAsc ? "asc" : "desc"}
                >
                  Amount
                </TableSortLabel>
              </TableCell>

              <TableCell align="left">
                <TableSortLabel
                  onClick={() => changeSort("gameSessionId")}
                  active={sortByAttribute === "gameSessionId"}
                  direction={sortDirectionAsc ? "asc" : "desc"}
                >
                  Session
                </TableSortLabel>
              </TableCell>

              <TableCell align="left">
                <TableSortLabel
                  onClick={() => changeSort("date")}
                  active={sortByAttribute === "date"}
                  direction={sortDirectionAsc ? "asc" : "desc"}
                >
                  Date
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortItems?.map((transaction: any, index: any) => {
              return (
                <PlayerPlayTransactionRow
                  key={transaction.id}
                  transaction={transaction}
                  index={index}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {_hasMore && _cursor && (
        <Button
          onClick={() => {
            setCursor(_cursor);
          }}
          fullWidth
          variant={"contained"}
          sx={{
            mt: 2,
          }}
        >
          Load More
        </Button>
      )}
    </Box>
  );
}

const toHumanReadableDate = (isoString: string) => {
  const date = new Date(isoString);
  const formattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  return `${formattedDate} at ${formattedTime}`;
};

const PlayerPlayTransactionRow = (props: {
  transaction: any;
  index: number;
}) => {
  const { transaction, index } = props;

  const isEven = index % 2 === 0;

  const transactionType =
    transaction.type === "BET" ? "PLAY" : transaction.type;

  return (
    <TableRow
      key={transaction.id}
      sx={{
        backgroundColor: isEven ? "#2f3140" : "#3d4155",
      }}
    >
      <TableCell align="left">{transactionType}</TableCell>
      <TableCell align="left">{transaction.gameName}</TableCell>

      <TableCell align="left">{transaction.amount}</TableCell>
      <TableCell
        align="left"
        sx={{
          cursor: "pointer",
        }}
      >
        <Tooltip title={transaction.gameSessionId}>
          {transaction.gameSessionId.slice(0, 3)}
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        {toHumanReadableDate(transaction.date)}
      </TableCell>
    </TableRow>
  );
};
