import {
  Alert,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import {
  useGetTransactionListByPlayerIdQuery,
  useGetRoomListQuery,
  TransactionType,
  useReverseDepositTransactionMutation,
} from "../generated/graphql";
import { orderBy } from "lodash";
import SvgIconStyle from "./other/SvgIconStyle";
import Iconify from "../components/Iconify";
import useAuth from "../hooks/useAuth";
import { MenuItem } from "@mui/material";
import toast from "react-hot-toast";

// ----------------------------------------------------------------------
interface PlayerTransactionsProps {
  player: any;
  getUpdatedPlayer: any;
}

export default function PlayerTransactions({
  player,
  getUpdatedPlayer,
}: PlayerTransactionsProps) {
  // Pagination
  const [cursor, setCursor] = useState<number | null>(null);
  const [filterValue, setFilterValue] = useState<TransactionType | undefined>(
    undefined
  );
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const [startDateISO, setStartDateISO] = useState<string | undefined>(
    undefined
  );
  const [endDateISO, setEndDateISO] = useState<string | undefined>(undefined);

  // Sorting
  const [sortDirectionAsc, setSortDirectionAsc] = useState<boolean>(false);
  const [sortByAttribute, setSortByAttribute] = useState<string>("date");

  // Concated List
  const [concatedList, setConcatedList] = useState<any[]>([]);

  const [
    { data: getPlayerTransactionsData, error: getPlayerTransactionsError },
  ] = useGetTransactionListByPlayerIdQuery({
    variables: {
      playerId: player.id,
      pagination: {
        limit: 50,
        cursor: cursor,
      },
      filter: {
        type: !filterValue ? undefined : filterValue,
        startDate: startDateISO ? startDateISO : undefined,
        endDate: endDateISO ? endDateISO : undefined,
      },
    },
    requestPolicy: "network-only",
  });

  const transactions =
    getPlayerTransactionsData?.getTransactionListByPlayerId.transactions;
  const _cursor =
    getPlayerTransactionsData?.getTransactionListByPlayerId.cursor;
  const _hasMore =
    getPlayerTransactionsData?.getTransactionListByPlayerId.hasMore;
  const _totalCount =
    getPlayerTransactionsData?.getTransactionListByPlayerId.totalCount;

  // Stats
  const _totalDepositsAndWithdraws =
    getPlayerTransactionsData?.getTransactionListByPlayerId
      .totalDepositsAndWithdraws;
  const _totalDeposits =
    getPlayerTransactionsData?.getTransactionListByPlayerId.totalDeposits;
  const _totalWithdraws =
    getPlayerTransactionsData?.getTransactionListByPlayerId.totalWithdraws;

  const _totalBonus =
    getPlayerTransactionsData?.getTransactionListByPlayerId.totalBonus;

  // concat transactions list on new data
  // - If previous data is same as new data then don't concat
  useEffect(() => {
    if (transactions) {
      if (cursor) {
        setConcatedList([...concatedList, ...transactions]);
      } else {
        setConcatedList(transactions);
      }
    }
  }, [transactions]);

  const sortItems = useMemo(() => {
    const direction = sortDirectionAsc ? "asc" : "desc";
    return orderBy(concatedList, (o) => o[sortByAttribute], direction);
  }, [concatedList, sortByAttribute, sortDirectionAsc]);

  const changeSort = (attribute: any) => {
    if (attribute === sortByAttribute) {
      const newSort = !sortDirectionAsc;
      setSortDirectionAsc(newSort);
    } else setSortByAttribute(attribute);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          mb: 3,
        }}
      >
        {/* Deposits/Withdrawal Stats */}
        <Box
          sx={{
            display: "flex",
          }}
        >
          {_totalDepositsAndWithdraws !== 0 &&
            !filterValue &&
            _totalDepositsAndWithdraws && (
              <Alert severity="info">
                Deposits Minus Withdrawals:{" "}
                {_totalDepositsAndWithdraws.toLocaleString()}
              </Alert>
            )}

          {_totalDeposits !== 0 &&
            filterValue === TransactionType.Deposit &&
            _totalDeposits && (
              <Alert severity="info">
                Total Deposits: {_totalDeposits.toLocaleString()}
              </Alert>
            )}

          {_totalWithdraws !== 0 &&
            filterValue === TransactionType.Withdraw &&
            _totalWithdraws && (
              <Alert severity="info">
                Total Withdraws: {_totalWithdraws.toLocaleString()}
              </Alert>
            )}

          {_totalBonus !== 0 &&
            _totalBonus &&
            filterValue !== TransactionType.Withdraw &&
            filterValue !== TransactionType.PetPackage && (
              <Alert sx={{ ml: 1 }} severity="info">
                Bonus: {_totalBonus.toLocaleString()}
              </Alert>
            )}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            mt: 3,
          }}
        >
          {/* Select Start Date */}
          <TextField
            size="small"
            id="startDate"
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(e) => {
              if (!e.target.value) {
                setStartDate(undefined);
                setStartDateISO(undefined);
                return;
              }
              // UTX time zone offset
              const timeZoneShortCode = new Date()
                .toLocaleString("en", { timeZoneName: "short" })
                .split(" ")
                .pop();
              const dateWithTimeZone =
                e.target.value + `, ${timeZoneShortCode}`;

              setStartDate(e.target.value);
              setStartDateISO(new Date(dateWithTimeZone).toISOString());
            }}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              mr: { xs: 0, sm: 1 },
              mb: { xs: 1, sm: 0 },
              "& fieldset": {
                backgroundColor: "background.neutral",
              },
              '& input[type="date"]::-webkit-calendar-picker-indicator': {
                filter:
                  "invert(50%) sepia(100%) saturate(500%) hue-rotate(330deg)",
                scale: "1.5",
              },
            }}
          />
          {/* End Date */}
          <TextField
            size="small"
            id="endDate"
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => {
              if (!e.target.value) {
                setEndDate(undefined);
                setEndDateISO(undefined);
                return;
              }
              // UTX time zone offset
              const timeZoneShortCode = new Date()
                .toLocaleString("en", { timeZoneName: "short" })
                .split(" ")
                .pop();
              const dateWithTimeZone = new Date(
                e.target.value + `, ${timeZoneShortCode}`
              );
              // Important to set the hours on the endDate to 23:59:59
              dateWithTimeZone.setHours(23, 59, 59, 999);
              setEndDate(e.target.value);
              setEndDateISO(dateWithTimeZone.toISOString());
            }}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              mr: { xs: 0, sm: 1 },
              mb: { xs: 1, sm: 0 },
              "& fieldset": {
                backgroundColor: "background.neutral",
              },
              '& input[type="date"]::-webkit-calendar-picker-indicator': {
                filter:
                  "invert(50%) sepia(100%) saturate(500%) hue-rotate(330deg)",
                scale: "1.5",
              },
            }}
          />
          {/* Select Transaction Type */}
          <FormControl
            size="small"
            variant="outlined"
            sx={{
              minWidth: 150,
              width: { xs: "100%", sm: "auto" },
            }}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Filter
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label="Filter"
              value={filterValue}
              onChange={(e: any) => {
                setFilterValue(e.target.value);
                setCursor(null);
              }}
              sx={{
                "& fieldset": {
                  backgroundColor: "background.neutral",
                },
              }}
            >
              <MenuItem value={undefined}>No Filter</MenuItem>
              <MenuItem value={TransactionType.Deposit}>Deposits</MenuItem>
              <MenuItem value={TransactionType.Withdraw}>Withdrawals</MenuItem>
              <MenuItem value={TransactionType.PetPackage}>
                Pet Package
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <TableContainer sx={{ overflow: "hidden" }} component={Paper}>
        <Table aria-label="collapsible table" sx={{ overflowX: "scroll" }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#2d2f38" }}>
              {/* Arrow Icon */}
              <TableCell align="left" />

              <TableCell align="left">
                <TableSortLabel
                  onClick={() => changeSort("type")}
                  active={sortByAttribute === "type"}
                  direction={sortDirectionAsc ? "asc" : "desc"}
                >
                  Transaction Type
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">
                <TableSortLabel
                  onClick={() => changeSort("amount")}
                  active={sortByAttribute === "amount"}
                  direction={sortDirectionAsc ? "asc" : "desc"}
                >
                  Amount
                </TableSortLabel>
              </TableCell>

              {/* Bonus */}
              <TableCell align="left">
                <TableSortLabel
                  onClick={() => changeSort("bonus")}
                  active={sortByAttribute === "bonus"}
                  direction={sortDirectionAsc ? "asc" : "desc"}
                >
                  Bonus
                </TableSortLabel>
              </TableCell>

              <TableCell align="left">
                <TableSortLabel
                  onClick={() => changeSort("date")}
                  active={sortByAttribute === "date"}
                  direction={sortDirectionAsc ? "asc" : "desc"}
                >
                  Date
                </TableSortLabel>
              </TableCell>

              <TableCell align="left">
                <TableSortLabel
                  onClick={() => changeSort("adminName")}
                  active={sortByAttribute === "adminName"}
                  direction={sortDirectionAsc ? "asc" : "desc"}
                >
                  Admin
                </TableSortLabel>
              </TableCell>

              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {sortItems?.map((transaction: any, index: any) => {
              return (
                <PlayerTransactionRow
                  key={transaction.id}
                  transaction={transaction}
                  index={index}
                  getUpdatedPlayer={getUpdatedPlayer}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {_hasMore && _cursor && (
        <Button
          onClick={() => {
            setCursor(_cursor);
          }}
          fullWidth
          variant={"contained"}
          sx={{
            mt: 2,
          }}
        >
          Load More
        </Button>
      )}
    </Box>
  );
}

const toHumanReadableDate = (isoString: string) => {
  const date = new Date(isoString);
  const formattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  return `${formattedDate} at ${formattedTime}`;
};

const PlayerTransactionRow = (props: {
  transaction: any;
  index: number;
  getUpdatedPlayer: any;
}) => {
  const { activeRoomId } = useAuth();
  const { transaction, index, getUpdatedPlayer } = props;

  const [open, setOpen] = useState(false);
  const [openPrint, setOpenPrint] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showReverseDialog, setShowReverseDialog] = useState(false);

  const [{ data, error }, reverseTransaction] =
    useReverseDepositTransactionMutation();

  const [{ data: roomListData }] = useGetRoomListQuery({
    pause: !openPrint,
  });
  const rooms = roomListData?.getRoomList;
  const room = rooms?.find((r: any) => r.id === activeRoomId);

  const isEven = index % 2 === 0;

  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = printStyles;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  const handleReverseTransaction = async () => {
    try {
      const response = await reverseTransaction({
        transactionId: transaction.id,
      });
      if (response.error) {
        throw new Error("Failed to reverse transaction");
      }
      toast.success("Transaction Reversed Successfully!");
      setShowReverseDialog(false);
      await getUpdatedPlayer({
        requestPolicy: "network-only",
      });
    } catch (error) {
      toast.error("Failed to reverse transaction");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <TableRow
        onClick={() => {
          if (transaction.type == TransactionType.Deposit) {
            setOpen(!open);
          }
        }}
        key={transaction.id}
        sx={{
          backgroundColor: isEven ? "#2f3140" : "#3d4155",
          height: "45px",
          transition: "all 0.3s ease",
          opacity: 0.9,
          "&:hover": {
            opacity: 1,
            transform: "scale(1.01)",
            // background: "#d4d4d4",
          },
        }}
      >
        {transaction.type == TransactionType.Deposit && (
          <TableCell
            align="left"
            onClick={(e) => {
              e.stopPropagation();
              setOpen(!open);
            }}
          >
            <>
              {open ? (
                <SvgIconStyle
                  src={`/assets/arrow-up.svg`}
                  sx={{
                    width: 25,
                    height: 25,
                    my: "auto",
                  }}
                />
              ) : (
                <SvgIconStyle
                  src={`/assets/dropdown-arrow.svg`}
                  sx={{
                    width: 25,
                    height: 25,
                    my: "auto",
                  }}
                />
              )}
            </>
          </TableCell>
        )}

        {transaction.type !== TransactionType.Deposit && (
          <TableCell align="left">
            <></>
          </TableCell>
        )}

        <TableCell align="left">{transaction.type}</TableCell>
        <TableCell align="left">{transaction.amount}</TableCell>

        <TableCell align="left">{transaction.bonus}</TableCell>

        <TableCell align="left">
          {toHumanReadableDate(transaction.date)}
        </TableCell>
        <TableCell align="left">{transaction.adminName}</TableCell>

        <TableCell align="right" onClick={(e) => e.stopPropagation()}>
          {transaction.type === TransactionType.Deposit && (
            <IconButton
              sx={{
                mr: 1,
              }}
              onClick={(e) => {
                e.stopPropagation();
                setShowReverseDialog(true);
              }}
            >
              <Iconify icon="mage:refresh-reverse" />
            </IconButton>
          )}

          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setOpenPrint(true);
            }}
          >
            <Iconify icon="mdi:printer" />
          </IconButton>
        </TableCell>
      </TableRow>

      {/* Collapsable Row */}
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="nested table">
                <TableHead>
                  <TableRow>
                    <TableCell> Package</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Free Entries</TableCell>
                    <TableCell> Candy </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transaction.packages?.map((p: any) => (
                    <TableRow key={p.id}>
                      <TableCell component="th" scope="row">
                        {p.name}
                      </TableCell>
                      <TableCell>X {p.amount}</TableCell>
                      <TableCell>{p.entries}</TableCell>
                      <TableCell>{p.candy}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      {/* Print Dialog */}
      <Dialog
        open={openPrint}
        onClose={() => setOpenPrint(false)}
        sx={{
          "& .MuiDialog-paper": {
            width: "300px",
            backgroundColor: "#ffffff",
            color: "#000000",
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center", fontSize: "12px !important" }}>
          *** RECEIPT ***
        </DialogTitle>
        <DialogContent sx={{ textAlign: "center", fontSize: "10px" }}>
          <Box className="print-content">
            <Typography sx={{ fontSize: "12px" }}>{room?.name}</Typography>
            <Typography sx={{ fontSize: "12px" }}>{room?.location}</Typography>
            <br />
            <Typography sx={{ fontSize: "12px" }}>
              {new Date(transaction.date).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </Typography>
            <Typography sx={{ fontSize: "12px" }}>
              {new Date(transaction.date).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              })}
            </Typography>
            <br />
            <Typography sx={{ fontSize: "12px" }}>PLAYER #:</Typography>
            <Typography sx={{ fontSize: "10px" }}>
              {transaction.playerName}
            </Typography>
            <br />
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography sx={{ fontSize: "12px" }}>RECEIPT #:</Typography>
              <Typography sx={{ fontSize: "12px" }}>
                {transaction.id}
              </Typography>
            </Box>
            <br />
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography sx={{ fontSize: "12px" }}>
                {transaction.type}:
              </Typography>
              <Typography sx={{ fontSize: "12px" }}>
                {transaction.amount.toLocaleString()}
              </Typography>
            </Box>
            {transaction.bonus > 0 && (
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{ fontSize: "12px" }}>BONUS:</Typography>
                <Typography sx={{ fontSize: "12px" }}>
                  {transaction.bonus.toLocaleString()}
                </Typography>
              </Box>
            )}
            <br /> <br />
            <Typography sx={{ fontSize: "12px" }}>
              Thank you for playing!
            </Typography>
            <Typography sx={{ fontSize: "12px" }}>Disclaimed:</Typography>
            <Typography sx={{ fontSize: "12px" }}>
              1. Operator and Sponsor are not responsible for lost or stolen
              receipts or cards.
            </Typography>
            <Typography sx={{ fontSize: "12px" }}>
              2. For security, keep your PIN# safe!
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpenPrint(false)}>
            Close
          </Button>
          <Button variant="contained" onClick={() => window.print()}>
            Print
          </Button>
        </DialogActions>
      </Dialog>

      {/* Reverse Deposit Transaction  */}
      <Dialog
        onClose={() => setShowReverseDialog(false)}
        open={showReverseDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2, bgcolor: "#2e3c5a" }}>
          <Typography variant="h4">Reverse Transaction</Typography>
          <IconButton
            aria-label="close"
            onClick={() => setShowReverseDialog(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ep:close-bold" />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            minWidth: "500px",
            bgcolor: "#2e3c5a",
          }}
        >
          <Typography variant="body2" sx={{ mb: 2 }}>
            Are you sure you want to reverse this transaction?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ bgcolor: "#2e3c5a" }}>
          <Button
            variant="outlined"
            onClick={() => setShowReverseDialog(false)}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            variant="contained"
            autoFocus
            onClick={async (e) => {
              setLoading(true);
              await handleReverseTransaction();
            }}
          >
            Reverse Transaction
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const printStyles = `
  @media print {
    .hide-on-print {
      display: none !important;
    }
    header, footer {
    display: none;
    }

    /* Hide dialog actions when printing */
    .MuiDialogActions-root {
      display: none !important;
    }
    
    /* Only print the dialog content */
    body > *:not(.MuiDialog-root) {
      display: none;
    }
    
    .print-content {
      padding: 20px;
    }
  }
`;
